import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/feiten-over-diarree/chronische-diarree/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/la-diarrhee/la-diarrhee-chronique/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="d14a24c47597de188073e0959b000300335f094d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werklast
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                      Recepten
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            {/*<div className="widget">
              <div className="inner">
                <h4>Diagnose prikkelbare darm</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_stress_durchfall.jpeg"
                    width="170"
                    height="102"
                    alt="Diagnose prikkelbare darm"
                  />
                </div>
                <p>
                  Hier vind je informatie over de oorzaken en behandeling van
                  prikkelbare darm.
                </p>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Meer informatie
                </a>
              </div>
            </div>*/}
          </div>
          <div className="page nine columns page-27">
            <h1>Chronische Diarree</h1>
            <div className="img">
              <img
                src="/assets/files/pages/chronische-diarree.jpeg"
                width="701"
                height="289"
                alt="Chronische Diarree"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>chronische diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  Als je 2 weken of langer diarree hebt of het steeds opnieuw
                  krijgt, lijd je aan zogenaamde 'chronische diarree'.
                  <br />
                  Chronische diarree kan leiden tot verzwakking en uitdroging.
                  Daarom is het zeer belangrijk dat je bij{" "}
                  <strong>aanhoudende diarree</strong> je arts raadpleegt.
                </p>
              </div>
            </div>
            <p>
              Om de oorzaak te vinden van uw chronische diarree zal je huisarts
              je misschien vragen om een monster van uw ontlasting, waarin dan
              gezocht kan worden naar bacteriën of parasieten. Je arts kan ook
              een bloedtest uitvoeren of je doorverwijzen voor een
              darmonderzoek.
            </p>
            <p>
              Het kan soms wel even duren voordat de{" "}
              <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                oorzaak van je diarree
              </a>{" "}
              gevonden wordt. Maar eens men weet wat er aan de hand is kan er
              een gepaste behandeling worden opgestart.
            </p>
			{/*<p>
			  Diagnose Prikkelbaredarmsyndroom (PDS)
			</p>*/}
			<h3>Diagnose Prikkelbaredarmsyndroom (PDS)</h3>
			<p>
			  Heb jij last van een prikkelbare darm? Imodium® biedt hulp bij de behandeling van 
			  diarreesymptomen bij het prikkelbaredarmsyndroom*.
			  Imodium® vermindert de symptomen van diarree bij PDS. Loperamide, het actieve 
			  bestanddeel van Imodium®, maakt je stoelgang vaster en zorgt voor een kortere 
			  periode van diarree.
			</p>
			<p>
			  * Bij periodes van acute diarree bij prikkelbaredarmsyndroom, vanaf 18 jaar en 
			  voorafgaand gediagnosticeerd door een arts. 
			</p>
			<p>
			  Heeft u nog vragen over uw symptomen of over prikkelbare darmsyndroom in het 
			  algemeen? Neem dan contact op met uw behandelend arts.
			</p>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>                        
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Michel de Braeystraat 52, 2000 Antwerpen, België. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
